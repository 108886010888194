import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'cws-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {

  @Input() selectedTabIndex = 0;
  @Input() tabNameArr!: Array<string>;
  @Input() enableNavigation = false;
  @Input() translationPrefix!: string;

  @Output() OnTabChange = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {
  }

  /* Function on tab click */
  tabChange(index: number): void {
    if (index === this.selectedTabIndex) return;
    this.selectedTabIndex = index;
    this.OnTabChange.emit(index);
  }

}
