<div class="date-selector">
    <!--label-->
    <div class="date-selector__label" *ngIf="this.displayLabel">
        <span class="labels">{{ translationPrefix + fieldName | translate}}</span>
        <span class="date-selector__label--required" *ngIf="this.isRequired">*</span>
    </div>

    <!-- separate date fields -->
    <app-date-fields [formControl]="this.formControl" [minYear]="minYear" [maxYear]="maxYear" [hideDatePicker]="hideDatePicker"
        [defaultYear]="defaultYear" [displayJapaneseYear]="displayJapaneseYear" class="date-selector__separate-fields">
    </app-date-fields>
</div>