import { Component } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { pipe, take } from 'rxjs';
import { UserData } from 'src/app/modals/interfaces/user-data-model';
import { LogoutService } from 'src/app/service/logout.service';
import { common_urls } from 'src/environments/environment';

@Component({
  selector: 'app-survey-logout-modal',
  templateUrl: './survey-logout-modal.component.html',
  styleUrls: ['./survey-logout-modal.component.scss']
})
export class SurveyLogoutModalComponent {

  constructor(private logoutService: LogoutService, private oidcSecurityService: OidcSecurityService) { }

  openModal() {
    const element = document.getElementById('modal-survey');
    element?.classList.add('open');
  }

  hideModal() {
    const element = document.getElementById('modal-survey');
    element?.classList.remove('open');
    document.body.style.overflow = 'auto';
  }

  // on click of survey button
  navigateToSurvey() {
    this.hideModal();
    window.open(common_urls.surveyUrl, '_blank');
    // this.oidcSecurityService.checkAuth().subscribe(({ userData }) => {
    //   const user_data:UserData = userData;
    //   const obj = {
    //     userid: user_data.user_id
    //   };
    //   this.logoutService.cwsSurveyCall(user_data.urls.custom_domain, obj)
    //   .pipe(take(1))
    //   .subscribe((res: string) => {
    //     console.log('check response>>>', res);
    //     window.open(res, '_blank');
    //     setTimeout(() => {
    //       this.logoutService.OIDClogout(`${common_urls.cwsBaseUrl}/secur/logout.jsp`);
    //     }, 1000);
    //   })
    // });
    setTimeout(() => {
      this.logoutService.OIDClogout(`${common_urls.cwsBaseUrl}/secur/logout.jsp`);
    }, 1000);
  }

  // on click of logout button without survey
  navigateToLogout() {
    this.hideModal();
    this.logoutService.OIDClogout(`${common_urls.cwsBaseUrl}/secur/logout.jsp`);
  }

}
