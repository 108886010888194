

<mat-dialog-content>
  <div class="modal open" id="termsConditions">
    <div class="modal-content">
      <div class="modal-body-footer-spacing">
          <div class="container-box reg-container-bg">
            <p class="bold">マイページのご利用にあたり、利用規約への同意および個人情報保護方針の確認が必要です。</p>
                        <p>利用規約に同意いただけない場合はマイページはご利用いただけません。</p>
          </div>
          <div class="modal-header regBoxHead">
            マニュライフ生命マイページ利用規約
          </div>
          <div class="noBottomPaddingLi cust-personal-info">
  
            <ul>
              <li>第1条（目的）</li>
              <li>マニュライフ生命マイページ利用規約（以下「本規約」といいます）は、マニュライフ生命保険株式会社（以下「当社」といいます）が当社の保険契約者（以下「契約者」といいます）に提供する「マニュライフ生命マイページ」（以下「本サービス」といいます）に関する取扱を定めています。</li><br>
              <li>第2条（本サービスの利用登録）　</li>
              <li>1. 契約者は、当社所定の登録手続に従い、本サービスの利用申込みを行うことができます。</li>
              <li>2. 当社が利用を認めたときは、契約者への通知をもって利用登録完了とします。（以下利用登録された契約者を「利用者」といいます）。尚、以下の各号のいずれかに該当する場合、当社は利用登録をしないことがあります。</li>
              <li>（１） 契約者が、登録手続その他において虚偽の内容を届け出た場合</li>
              <li>（２） 当社が利用者として不適格であると判断した場合</li>
              <li>3. 当社は、利用登録完了後でも、相応の事由があると判断した場合には、利用者に追加の情報提供を求めることができます。</li><br>
  
              <li>第3条（IDおよびパスワード）</li>
              <li>1. 利用者が指定したメールアドレス（以下「メールアドレス」といいます）をログインID（以下「ID」といいます）とします。また、利用者は所定の要件を満たすパスワードを設定します。</li>
              <li>2. 利用者は、ID・パスワードを自己の責任で厳重に管理し、第三者に譲渡、貸与等できません。</li>
              <li>3. 利用者のID・パスワードを用いてなされた全ての本サービスの利用は、利用者本人が行ったものとして取扱います。</li>
              <li>4. 利用者は、本サービスが他人に利用される可能性があることを認識した場合は、直ちに当社に通知します。当社は当該通知を受け、本サービスの提供を中断・中止します。</li>
              <li>5. 当社は、利用者のID・パスワードを他人に知られたために生じた損害の責任を負いません。</li><br>
  
              <li>第4条（本サービスの利用）</li>
              <li>1. 当社は、本サービスにおいて利用可能な機能を一覧表示します。尚、未成年の契約者に関わる本サービスの利用範囲は制限されます。</li>
              <li>2. 本サービスの利用に関わる利用者の意思表示は、当社が当該意思表示を受理したことをもって有効となります。</li><br>
  
              <li>第5条（利用者への通知）</li>
              <li>1. 本サービスの利用および本規約に基づく利用者への通知は、利用者が当社に登録したメールアドレス宛に発信し、当社がその内容を送信したときをもって到着したとみなします。但し、郵送等、当社が適当と認める方法により通知を行う場合があります。</li>
              <li>2. 本サービスの利用に関わる利用者の意思表示 は、マイページ上または受付完了通知に記載されている受付日から効力が発生します。</li>
              <li>3. 前項の通知が届かない場合、利用者は速やかにその旨当社に連絡します。</li>
              <li>4. 第2項の通知が届かない場合、利用者から前項の連絡がない限り、利用者の意思表示は無効になります。</li>
              <li>5. 当社から利用者への通知が届かないことが、利用者の受信設定などの当社の責めによらない事由による場合、これにより生じた損害について当社は責任を負いません。</li><br>
  
              <li>第6条（登録情報の変更）</li>
              <li>1. 利用者は、当社に届け出た住所・氏名・電話番号・メールアドレスその他登録情報に変更があった場合、速やかに当社所定の変更手続を行います。利用者が変更手続を行わなかったために、当社からの送信、通知、もしくは当社から送付された書類などが延着または到達しなかった場合においても、通常到達すべき時点に到着したものとみなします。</li>
              <li>2. 前項の変更は、当社の手続完了時から有効となり、当社はその旨を利用者に通知します。</li>
              <li>3. 前項の通知完了前に本サービスを利用したこと、又は利用できなかったことにより生じた損害について当社は責任を負いません。</li><br>
  
              <li>第7条（情報提供）</li>
              <li>当社は、利用者への各種情報サービスをメールアドレスへの送信その他当社所定の方法により提供します。</li><br>
  
              <li>第8条（著作権等）</li>
              <li>本サービスを構成する文章、画像その他の著作物は、当社又は当社に利用を許諾する第三者に帰属し、利用者は本サービスの利用範囲内でこれらを利用します。</li><br>
  
              <li>第9条（禁止行為等）</li>
              <li>利用者は以下の各号の行為を行ってはなりません。</li>
              <li>（１）本サービスの利用に際して、虚偽の事実を利用する行為、または他人に成り済ます行為</li>
              <li>（２）ＩＤ・パスワードを不正に使用する行為</li>
              <li>（３）本サービスのシステム、ネットワークその他の正常な運用を阻害する行為</li>
              <li>（４）本サービスを本来の目的とは異なる目的で利用する行為</li>
              <li>（５）法令または公序良俗に反する行為</li>
              <li>（６）その他当社が不適切と判断する行為</li><br>
  
              <li>第10条（本サービスの変更・中断・廃止）</li>
              <li>1. 当社は、当社の都合により本サービスの内容変更、中断又は廃止をすることができます。</li>
              <li>2. 当社は、本サービスの提供に必要な設備等の保守・点検を行う場合、又は天災、停電、通信の遮断等により本サービスの提供が困難となった場合、本サービスの提供を中断することがあります。</li>
              <li>3. 前各項により利用者に損害が生じても、当社は当該損害について責任を負いません。</li><br>
  
              <li>第11条（利用の停止措置、および利用登録抹消）</li>
              <li>当社は、利用者が当社の契約者でなくなった場合、又は本規約に違反した場合、その他当社が相応の事由があると判断した場合、本サービスの利用の停止、または利用登録抹消をすることができます。</li><br>
  
  
  
              <li>第12条（本規約の改定）</li>
              <li>1. 当社は、本規約を改定する場合、事前にその内容および改定日を当社ホームページに公表します。</li>
              <li>2. 本規約改定後、利用者が本サービスを利用したことをもって、当該改定に同意したものとみなします。</li><br>
  
  
              <li>第13条（当社の免責）</li>
              <li>当社は、本サービスの内容や情報の正確性を維持することに努め、また合理的なセキュリティ対策を実施します。但し、インターネット通信の性格上、内容や情報の完全性及び有用性の保証は行わないものとし、利用者が本サービスを利用したこと、又は本サービスを利用できなかったことによるいかなる損害の責任も負いません。また、当社が何らかの理由により責任を負う場合であっても、利用者の損害は直接損害に限定され、付随的損害、間接損害、特別損害、将来の損害および逸失利益にかかる損害については、当社は賠償する責任を負いません。</li><br>
  
              <li>第14条（準拠法および合意管轄）</li>
              <li>1. 本規約は、日本法により解釈されます。</li>
  
              <li>2. 本サービスに関する紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</li><br>
  
  
              <li>&lt;付則&gt;</li>
              <li>第1条（本規約の適用）</li>
              <li>本規約は平成28（2016）年1月1日から適用します。</li>
              <li>（平成28年1月制定）</li><br>

              <li>第２条（本規約の改定）</li>
              <li>本規約を改定し、令和6（2024）年9月19日から適用します。</li>
  
  
          </ul>
      </div>
      <div>&nbsp;</div>
      <div class="modal-header regBoxHead  clearfix">
        個人情報保護方針
      </div>
      <div class="noBottomPaddingLi cust-personal-info">
        <ul>
           <li>利用者の個人情報は、常に最新のプライバシーポリシー (<a href="http://www.manulife.co.jp/privacypolicy" target="_blank">http://www.manulife.co.jp/privacypolicy</a>) に従って取扱います。</li>
        </ul>
        </div>
          <div class="modal-footer">
            <button class="btn btn2" mat-flat-button (click)="closeTermsConditionsPopUp()">
                <span>利用規約を確認</span><br>
            </button>
          </div>
      </div>
    </div>
  </div>
</mat-dialog-content>