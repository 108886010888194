export enum ReissueErrorCodes{
  CWSREI001 = "CWSREI001",
  CWSREI002 = "CWSREI002",
  CWSREI003 = "CWSREI003",
  CWSREI004 = "CWSREI004",
  CWSREI005 = "CWSREI005",
  CWSREI006 = "CWSREI006",
  CWSREI007 = "CWSREI007",
  CWSREI008 = "CWSREI008",
  CWSREI009 = "CWSREI009",
  CWSREI010 = "CWSREI010"
}

export enum CancellationErrorCodes{
  CWSTDSB001 = "CWSTDSB001",
  CWSTDSB002 = "CWSTDSB002",
  CWSTDSB003 = "CWSTDSB003",
  CWSTDSB004 = "CWSTDSB004",
  CWSTDSB005 = "CWSTDSB005",
  CWSTDSB006 = "CWSTDSB006",

  CWSRWS001 = "CWSRWS001",
  CWSRWS002 = "CWSRWS002",
  CWSRWS003 = "CWSRWS003",
  CWSRWS004 = "CWSRWS004",
  CWSRWS005 = "CWSRWS005",
  CWSRWS006 = "CWSRWS006",
  CWSRWS007 = "CWSRWS007"
}

export enum ProcedurePremiumInsuranceErrorCodes{
  CWSBAC001 = "CWSBAC001",
  CWSBAC002 = "CWSBAC002",

  CWSCCC001 = "CWSCCC001",
  CWSCCC002 = "CWSCCC002",
  CWSCCC003 = "CWSCCC003",
  CWSCCC004 = "CWSCCC004",
  CWSCCC005 = "CWSCCC005",
  CWSCCC006 = "CWSCCC006",

  CWSURB001 = "CWSURB001",
  CWSURB002 = "CWSURB002",
  CWSURB003 = "CWSURB003",
  CWSURB004 = "CWSURB004",
  CWSURB005 = "CWSURB005",
  CWSURB006 = "CWSURB006",
  CWSURB007 = "CWSURB007",
  CWSURB008 = "CWSURB008",
  CWSURB009 = "CWSURB009",
  CWSURB010 = "CWSURB010",
  CWSURB011 = "CWSURB011",
  CWSURB012 = "CWSURB012"
}

export enum LoansFundWithdrawalsErrorCodes{
  CWSUPL001 = "CWSUPL001",
  CWSUPL002 = "CWSUPL002",
  CWSUPL003 = "CWSUPL003",
  CWSUPL004 = "CWSUPL004",
  CWSUPL005 = "CWSUPL005",
  CWSUPL006 = "CWSUPL006",
  CWSUPL007 = "CWSUPL007",
  CWSUPL008 = "CWSUPL008"
}