import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { common_urls } from 'src/environments/environment';
import { InactivityService } from './inactivity.service';

@Injectable({
  providedIn: 'root'
})
export class CwsRedirectService {
  cwsErrorPage: string = `${common_urls.cwsBaseUrl}/error_page`;

  constructor(private inactivityService: InactivityService,
              private oidcSecurityService: OidcSecurityService) { }

  /* Function to redirect user on cws */
  redirectToCWSBack() {
    this.inactivityService.stopTrackingInactivity();
    // window.location.href = this.cwsBackUrl;
    this.oidcSecurityService.logoffLocal();
    console.log('local logoff called');

    const url: string = sessionStorage.getItem('cwsBackUrl') || '';
    window.open(decodeURIComponent(url), '_self');
  }

  /* Function to redirect user on cws error page */
  redirectToCWSErrorPage(): void {
    this.inactivityService.stopTrackingInactivity();
    this.oidcSecurityService.logoffLocal();
    window.open(this.cwsErrorPage, '_self');
  }

  /* Function to redirect user to cws procUrl (Procedure) url page */
  redirectToCwsProcUrl(): void {
    this.inactivityService.stopTrackingInactivity();
    this.oidcSecurityService.logoffLocal();
    const url: string = sessionStorage.getItem('procUrl') || '';
    let decodedUrl: string = decodeURIComponent(url);
    if(decodedUrl.indexOf('home') >= 0 || decodedUrl.indexOf('policyinquiry') >= 0)
    {
      decodedUrl+='&cws=1';
    }
    window.open(decodedUrl, '_self');
  }
}
