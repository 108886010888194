<div class="modal__modal-content__modal-header modal-header">
  <h5 class="u-font-normal"> {{data.header| translate}} </h5>
  <span class="modal__modal-content__modal-header__close" *ngIf="data.actions.close.visible" [mat-dialog-close]="false">&times;</span>
</div>
<mat-dialog-content>
  <div class="modal__modal-content__modal-body modal-body">
    <ng-container *ngTemplateOutlet="data.customBody"></ng-container>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button *ngIf="data.actions.ok.visible" class="btn btn2 modal__modal-content__modal-footer__btnok c-button"
    mat-flat-button [mat-dialog-close]="true">
    {{data.actions.ok.label | translate}}
  </button>
  <button *ngIf="data.actions.cancel.visible" class="btn btn3 c-button" mat-flat-button [mat-dialog-close]="false">
    {{data.actions.cancel.label | translate}}
  </button>
</mat-dialog-actions>