import { yearList } from "./date-selector.constants";
import { Day, Year } from "./date-selector.model";

/**
 * method to get days available i a month for selection
 * @param year year for which we need days
 * @param month month for which we need days
 * @returns 
 */
export function getDays(year?: number, month?: number): Array<Day> {
    const days: Array<Day> = [];
    const startDay = 1;
    let lastDay = 31;

    //if both year and month are available
    if (year && month) {
        //if month is february we'll check if the year is leap year and decide the last date accordingly
        if (month == 2) {
            if (year % 4 == 0) {
                lastDay = 29;
            } else {
                lastDay = 28;
            }
        } else if ([1, 3, 5, 7, 8, 10, 12].includes(month)) {
            //checking if month is January, March, May, July, August, October or December then last date would be 31
            lastDay = 31;
        } else {
            lastDay = 30;
        }
    } else if (year) {
        //if only year is passed then last day will be considered as 31
        lastDay = 31;
    } else if (month) {
        //if only month is passed we'll initialise last day accordingly
        if (month == 2) {
            lastDay = 28;
        } else if ([1, 3, 5, 7, 8, 10, 12].includes(month)) {
            lastDay = 31;
        } else {
            lastDay = 30;
        }
    }

    for (let i = startDay; i <= lastDay; i++) {
        days.push({
            day: i,
            displayText: prefixZeros(i, 2)
        });
    }

    return days;
}

/**
 * method to prefix zero in numeric string for eg if we need two digit representation for 1, then this method will produce 01
 * @param number number for which we need numeric string
 * @param maxDigits length of the number needed
 * @returns numeric string for the number prefixed with zeros as per the passed parameters
 */
export function prefixZeros(number: number, maxDigits: number): string {
    var length = maxDigits - number.toString().length;
    if (length <= 0)
        return number.toString();

    var leadingZeros = new Array(length + 1);
    return leadingZeros.join('0') + number.toString();
}

/**
 * this method checks if the date object is valid or not
 * @param date date object which needs to be checked is passed as input
 * @returns it returns boolean value true if the date object is valid otherwise it returns false
 */
export function isValid(date: Date): boolean {
    return date instanceof Date && !isNaN(+date);
}

/**
 * This method would return the ISO string i.e. yyyy-mm-dd of the passed date object
 * @param date object for which date string is needed
 * @returns ISO yyyy-mm-dd string of the date object
 */
export function getDateString(date: Date): string {
    const monthStr = date.getMonth() > 8 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
    const dateStr = date.getDate() > 9 ? (date.getDate()) : '0' + (date.getDate());
    const yearStr = date.getFullYear();
    return `${yearStr}-${monthStr}-${dateStr}`;
}

/**
 * method to retrieve the list of year
 * @param minYear initial year in yearList
 * @param maxYear final year in the year list
 * @returns returns the year list based on min and max year
 */
export function getYearList(minYear?: number, maxYear?: number): Array<Year> {
    if (minYear && maxYear)
        return yearList.filter(yearObj => yearObj.year >= minYear && yearObj.year <= maxYear);
    else if (minYear)
        return yearList.filter(yearObj => yearObj.year >= minYear);
    else if (maxYear)
        return yearList.filter(yearObj => yearObj.year <= maxYear);
    else
        return yearList;
}