import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { distinctUntilChanged, takeUntil } from 'rxjs';
import { FieldBaseComponent } from '../../field-base.component';
import { DateFieldsComponent } from '../date-fields/date-fields.component';

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateSelectorComponent extends FieldBaseComponent implements OnInit {

  @ViewChild(DateFieldsComponent, { static: true }) public dateFieldControl!: DateFieldsComponent;

  //initial year
  @Input() minYear = 1900; // setting default as 1900 if min year is not passed

  //final year
  @Input() maxYear = new Date().getFullYear();// setting default as current if max year is not passed

  //default year in order to preselect the year, don't pass the default year if your are initializing a value in a form
  @Input() defaultYear!:number;

  @Input() displayJapaneseYear = false;

  @Input() hideDatePicker = false;


  get formControl() {
    return this.control as FormControl;
  }

  constructor() {
    super();
  }

  public ngOnInit() {

    // if the separateDate is in an error state,
    // we need to return an invalidDate validation error
    const validator: ValidatorFn = (control: AbstractControl): { invalidDate: true } | null => {

      if (this.dateFieldControl.errorState) {
        return { invalidDate: true };
      }

      return null;
    };

    const nextValidators = [
      /* istanbul ignore next */
      this.control.validator ? this.control.validator : null,
      validator,
    ].filter(Boolean);

    this.control.setValidators(nextValidators as ValidatorFn[]);

    // update the validity when the focus changes
    // this allows us to maintain a valid status
    // while the user is inputting data, but will
    // evaluate again when the user leaves the field
    this.dateFieldControl.focused$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      ).subscribe(() => {
        this.control.updateValueAndValidity();
      });

  }
}

