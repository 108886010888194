import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-terms-and-condition-dialog',
  templateUrl: './terms-and-condition-dialog.component.html',
  styleUrls: ['./terms-and-condition-dialog.component.scss']
})
export class TermsAndConditionDialogComponent {

  constructor(public dialog: MatDialogRef<TermsAndConditionDialogComponent>) {
  }

  closeTermsConditionsPopUp() {
    this.dialog.close('1')
  }

}
