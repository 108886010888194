import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { common_urls } from './environments/environment';

const script = document.createElement('script');
script.src = common_urls.adobeAnalyticsUrl;
document.head.appendChild(script);
const code = document.createElement('script');
code.innerHTML = `var adobeDataLayer=  adobeDataLayer|| [];`
document.head.appendChild(code);


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
