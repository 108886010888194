<div class="password-field">
    <!--label for password field-->
    <div class="password-field__label" *ngIf="this.displayLabel">
        {{ this.translationPrefix + fieldName | translate }}
        <span class="password-field__label--required" *ngIf="this.isRequired">*</span>
    </div>

    <!--input field for password-->
    <div class="password-field__input">
        <input [type]="isPasswordVisible ? 'text' : 'password'"
            placeholder="{{this.translationPrefix + placeHolder | translate}}" value=""
            class="password-field__input__element" [formControl]="formControl"
            [maxLength]="maxLength" (paste)="(false)"
            [ngClass]="{'has-err':formControl.invalid && formControl.touched , 'has-success':formControl.valid && formControl.touched}">
            <div class="password-field__input__status">
                <button class="password-field__input__status__switch" [ngClass]="{'password-field__input__status__visible':isPasswordVisible}" type="button"
                (click)="isPasswordVisible=!isPasswordVisible"></button>
                <span *ngIf="showGreenCheck" class="password-field__input__status__green-check"></span>
            </div>
            
    </div>
</div>