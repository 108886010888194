import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, ElementRef, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { removeAllOccurrences } from '../../common-helpers';

@Component({
  selector: 'app-security-number-fields',
  templateUrl: './security-number-fields.component.html',
  styleUrls: ['./security-number-fields.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SecurityNumberFieldsComponent),  // replace name as appropriate
      multi: true
    }
  ]
})
export class SecurityNumberFieldsComponent implements ControlValueAccessor, OnInit, OnDestroy {
  
  @Input()
  firstBoxMaxLength = 3;

  @Input()
  firstBoxPlaceHolder!:string;
  
  @Input()
  secondBoxMaxLength = 7;

  @Input()
  secondBoxPlaceHolder!:string;

  @Input()
  translationPrefix!:string;


  public parts!: FormGroup;

  public focused: boolean = false;

  public onChange!: ((_: any) => {});

  public onTouched = () => { };

  @Input()
  public get value(): string | null {

    const { left, right } = this.parts.getRawValue();

    // can't do this approach because the right side could become zero-padded
    // const combined = toNumber(left) * 10000 + toNumber(right);

    const combined = Number(left) + Number(right);
    if (combined === 0) {
      return null;
    }

    return `${left || ''}-${right || ''}`;
  }

  public set value(input: string | null) {

    if (!input) {
      this.parts.reset();
      
      return;
    }

    /* istanbul ignore else */
    if (typeof input === 'string') {
      input = removeAllOccurrences(input, ['-', 'ー']);
    }

    const asNumber = Number(input);
    if (isNaN(asNumber)) {
      this.parts.reset();
      
      return;
    }

    const left = input.substring(0, 3);
    const right = input.substring(3);

    this.parts.patchValue({ left, right });

    
  }

  @Input()
  public get disabled(): boolean {
    return this._disabled;
  }
  public set disabled(value: boolean) {
    this._disabled = value;
    this._disabled ? this.parts.disable() : this.parts.enable();
    
  }
  private _disabled = false;

  public get empty(): boolean {
    const { left, right } = this.parts.value;
    return !left && !right;
}

  constructor(
    private formBuilder: FormBuilder,
    private focusMonitor: FocusMonitor,
    private elementRef: ElementRef<HTMLElement>
  ) { }

  ngOnInit(): void {
    this.parts = this.formBuilder.group({
      left: [null],
      right: [null],
    });

    this.focusMonitor.monitor(this.elementRef, true).subscribe(origin => {

      if (this.focused && !origin) {
        this.onTouched();
      }

      this.focused = !!origin;

      
    });

  }

  public writeValue(incoming: string): void {
    this.value = incoming;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public ngOnDestroy(): void {
    this.focusMonitor.stopMonitoring(this.elementRef);
  }

  public onContainerClick(event: MouseEvent): void {
    const elem = (event.target as Element);
    const isInput = elem.tagName.toLowerCase() === 'input';

    if (!isInput || this.empty) {
        // 1. if clicking inside the container, but not in an input, focus on the first input
        // 2. if clicking on an input, move to the first one if there is no existing value
        this.focusMonitor.focusVia(this.elementRef.nativeElement.querySelector('input') as HTMLElement, 'program');
    }
}

}
