import { Component } from '@angular/core';
import { LogoutService } from 'src/app/service/logout.service';
import { common_urls } from 'src/environments/environment';

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss']
})
export class LogoutModalComponent {

  constructor(private logoutService: LogoutService) { }

  openModal() {
    const element = document.getElementById('modal-logout');
    element?.classList.add('open');
  }

  hideModal() {
    const element = document.getElementById('modal-logout');
    element?.classList.remove('open');
    document.body.style.overflow = 'auto';
  }

  // on click of logout button wihout survey
  navigateToLogout() {
    this.hideModal();
    this.logoutService.OIDClogout(`${common_urls.cwsBaseUrl}/secur/logout.jsp`);
  }

}
