<div class="modal" id="modal-information">
    <div class="modal-content">
      <div class="modal-header">
        <h2>{{'info-modal.header' | translate}}</h2>
        <!-- <span class="close" (click)="hideModal()">&times;</span> -->
      </div>
      <div class="modal-body-footer-spacing">
        <div class="modal-body">
            <p>{{'info-modal.body.text1' | translate}}</p>
            <p><a href="tel:0120-063-730">0120-063-730</a></p>
            <p>{{'info-modal.body.text2' | translate}}</p>
            <p>{{'info-modal.body.text3' | translate}}</p>
            <br>
            <p>{{'info-modal.body.text4' | translate}}</p>
            <p><a href="tel:0120-925-008">0120-925-008</a></p>
            <p>{{'info-modal.body.text5' | translate}}</p>
            <p>{{'info-modal.body.text6' | translate}}</p>
          </div>
          <div class="modal-footer">
            <button class="btn btn2" mat-flat-button (click)="hideModal()">
                <span>{{'info-modal.button-text' | translate}}</span><br>
            </button>
          </div>
      </div>
    </div>
  </div>
  