<div role="group" [formGroup]="parts" class="security-number" (click)="onContainerClick($event)">
    <span class="security-number__first-box">
        <input formControlName="left" type="text" placeholder="{{ translationPrefix + firstBoxPlaceHolder | translate }}"
        autocomplete="off" [maxlength]="firstBoxMaxLength" (input)="this.onChange(this.value)" appDigitOnly inputmode="numeric">
        <!-- [ngClass]="{'has-err':parts['invalid'] && parts['touched'] , 'has-success':parts['valid'] && parts['touched'] }"> -->
    </span>
    <span class="security-number__separator"></span>
    <span class="security-number__second-box">
        <input formControlName="right" type="text" placeholder="{{ translationPrefix +  secondBoxPlaceHolder | translate }}" 
        autocomplete="off" [maxlength]="secondBoxMaxLength" (input)="this.onChange(this.value)" appDigitOnly inputmode="numeric">
        <!-- [ngClass]="{'has-err':parts['invalid'] && parts['touched'] , 'has-success':parts['valid'] && parts['touched'] }" > -->
    </span>
</div>