import { FocusMonitor } from '@angular/cdk/a11y';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, forwardRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { GlobalSettings } from 'src/app/shared/GlobalSetting';
import { monthList } from '../date-selector.constants';
import { Day, Month, Year } from '../date-selector.model';
import { getDateString, getDays, getYearList, isValid, prefixZeros } from '../date-selector.util';

interface PartsFormModel {
  year?: number;
  month?: number;
  day?: number;
}

@Component({
  selector: 'app-date-fields',
  templateUrl: './date-fields.component.html',
  styleUrls: ['./date-fields.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateFieldsComponent),
      multi: true
    }
  ]
})
export class DateFieldsComponent implements ControlValueAccessor, OnInit {
  @ViewChild('picker')
  picker!: MatDatepicker<Date>;

  //initial year
  @Input() minYear!:number;

  //final year
  @Input() maxYear!:number;

  @Input() defaultYear!: number;

  @Input() displayJapaneseYear!: boolean;

  @Input() hideDatePicker!: boolean;

  minDate!: Date;
  maxDate!: Date;

  onChange: any = () => { };
  onTouch: any = () => { };

  isDisabled = false;

  yearList: Array<Year> = [];
  monthList: Array<Month> = monthList;
  dayList: Array<Day> = getDays();

  dateFormGroup!: FormGroup;
  ignoreChangeEvent: boolean = false;

  public errorStateChange$ = new BehaviorSubject<boolean>(false);

  public focused$ = new BehaviorSubject<boolean>(false);

  private destroy$ = new ReplaySubject<boolean>(1);

  public focused!: boolean;

  // tslint:disable-next-line: rxjs-finnish
  public stateChanges = new Subject<void>();

  constructor(private formBuilder: FormBuilder,
    private elementRef: ElementRef<HTMLElement>,
    private dateAdapter: DateAdapter<any>,
    private focusMonitor: FocusMonitor,
    private cdr: ChangeDetectorRef
    //@Optional() @Self() public ngControl: NgControl
  ) {
    //super();
    /* istanbul ignore next */
    this.focusMonitor.monitor(this.elementRef, true).subscribe(origin => {

      this.focused = !!origin;

      if (!this.focused) {
        this.onTouch();
      }

      this.focused$.next(this.focused);
      this.stateChanges.next();
    });

  }
  



  ngOnInit(): void {
    //this.ngControl.valueAccessor = this;
    this.dateAdapter.setLocale(GlobalSettings.CURRENT_LOCALE);
    this.minDate = new Date(`${this.minYear}`);
    this.maxDate = new Date(`${this.maxYear}-12-31`);

    this.dateFormGroup = this.formBuilder.group({
      year: [null, Validators.required],
      month: [null, Validators.required],
      day: [null, Validators.required]
    });

    this.yearList = getYearList(this.minYear, this.maxYear);

    /* istanbul ignore next */
    this.dateFormGroup.valueChanges.subscribe((val) => {
      if (val['year'] && val['month'] && val['day']) {
        const dateString = [val['year'], prefixZeros(val['month'], 2), prefixZeros(val['day'], 2)].join('-');
        this.onChange(dateString);
        this.cdr.detectChanges();
        this.selectPickerDate(new Date(dateString));
      }

      this.cdr.detectChanges();
      this.onTouch();
    })

    /**
   * checking if there is a default value passed, if pssed it will set the value in formgroup
   */
    /* istanbul ignore next */
    if (this.defaultYear) {
      this.dateFormGroup?.patchValue({
        year: this.defaultYear,
        month: 1,
        day: 1
      });


    }

  }


  /**
   * 
   * @param dateString this method will set the value in dateFormGroup only if the date is valid and in the format yyyy-mm-dd
   */
  writeValue(dateString: string): void {
    if (dateString) {
      const validFormat = /^\d{4}-\d{2}-\d{2}$/.test(dateString);
      if (validFormat) {
        const date = new Date(dateString);
        if (isValid(date)) {
          const dateParts = dateString.split('-');
          this.dateFormGroup?.patchValue({ year: Number(dateParts[0]), month: Number(dateParts[1]), day: Number(dateParts[2]) });
        }
      }
    }
  }

  /**
  * 
  * @param date will set the passed date in date picker, if undefined or no parameter is passed it will unselect the date picker
  */
  selectPickerDate(date?: Date) {
    this.ignoreChangeEvent = true;
    this.picker?.select(date as Date);
    this.ignoreChangeEvent = false;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    
    if (isDisabled)
      this.dateFormGroup?.disable();
    else
      this.dateFormGroup?.enable();
  }

  monthSelected(month: Month) {
    this.dayList = getDays(this.selectedYear, month.month);
    this.updateDay();
  }

  yearSelected(year: Year) {
    this.dayList = getDays(year.year, this.selectedMonth);
    this.updateDay();
  }

  /**update day will be called everytime year or month is selected */
  updateDay() {
    if (this.selectedDay > this.dayList[this.dayList.length - 1].day) {
      this.dateFormGroup?.patchValue({ day: this.dayList[this.dayList.length - 1].day });
    }
  }

  /**
   * getter methods for year date and month
   */
  get selectedDay() {
    return this.dateFormGroup?.controls['day'].value;
  }

  get selectedMonth() {
    return this.dateFormGroup?.controls['month'].value;
  }

  get selectedYear() {
    return this.dateFormGroup?.controls['year'].value;
  }

  /**
   * 
   * @param event when date is selected in date picker this method is called
   */
  dateChanged(event: MatDatepickerInputEvent<Date, string>): void {
    if (this.ignoreChangeEvent)
      return;

    const date = new Date(event.target.value as Date);
    const dateStr = getDateString(date);
    this.writeValue(dateStr);
    this.onTouch();
  }

  public get empty(): boolean {
    return Object.entries(this.formValue).every(([, val]) =>
      val === null || val === undefined || val === '');
  }

  public get formValue(): PartsFormModel {
    // ensure we return at least null for each property
    return {
      day: null,
      month: null,
      year: null,
      ...this.dateFormGroup?.value,
    } as PartsFormModel;
  }

  // public get isTouched(): boolean {
  //   //return (this.ngControl && this.ngControl.touched) as boolean;
  //   return false
  // }

  /**
     * Whether the control is fully filled
     */
  public get filled(): boolean {
    return Object.entries(this.formValue).every(([, val]) =>
      val !== null || val === undefined);
  }

  public get errorState(): boolean {
    // * this method gets called quite often,
    // * so keep the calculations light

    let hasError = false;

    if (this.empty) {
      // no error when the form is empty
      hasError = false;
    } else if (!this.filled && this.focused) {
      // if we are focused and still not filled completely, we
      // can assume it is in progress and we will return no error
      hasError = false;
    } else {
      hasError = !this.dateFormGroup.valid;
    }

    this.errorStateChange$.next(hasError);
    return hasError;
  }

  /* istanbul ignore next */
  public ngOnDestroy(): void {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef);

    this.destroy$.next(true);
    this.destroy$.complete();

    this.errorStateChange$.complete();
    this.focused$.complete();
  }
}


