import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription, distinctUntilChanged, filter } from "rxjs";

export function getTitleFromRoute(route: ActivatedRoute, lastTitle?: string): string | undefined {

    //if the title is available for the current route, it will be considered as lastAvailableTitle
    if (route.snapshot.data['cwsTitle']) {
        lastTitle = route.snapshot.data['cwsTitle'];
    }

    if (route.children.length == 0) {
        //if there are no more children, last available title will be returned
        return lastTitle;
    } else {
        for (let child of route.children) {
            //drilling down to get the last available title from the deepest child hierarchy
            const title = getTitleFromRoute(child, lastTitle);

            if (title) {
                return title;
            }
        }
    }

    //if there is no title, undefined will be returned
    return undefined;
}

@Injectable({
    providedIn: 'root'
})
export class CwsTitleService {

    private subscription$: Subscription = Subscription.EMPTY;

    /**
     * Returns true if this service is listening to the router in order to manage the titles
     */
    public get isMonitoring() {
        return this._isMonitoring;
    }

    private _isMonitoring = false;

    constructor(private titleService: Title,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private translateService: TranslateService) { };

    /**
    * Starts listening to the router in order to manage the title
    */
    startMonitoring() {
        if (this._isMonitoring) {
            return;
        }
        this._isMonitoring = true;

        this.subscription$ = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            distinctUntilChanged()).subscribe(() => {
                const title = getTitleFromRoute(this.activatedRoute);
                if (title) {
                    const translatedTitle = this.translateService.instant(title);
                    this.titleService.setTitle(translatedTitle);
                }

            });
    };

    /**
     * Stops listening to the router
     */
    stopMonitoring() {
        this.subscription$.unsubscribe();
        this.subscription$ = Subscription.EMPTY;
        this._isMonitoring = false;
    };

    public ngOnDestroy() {
        this.stopMonitoring();
    };
}