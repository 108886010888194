import { AbstractControl, FormGroup, ValidatorFn } from "@angular/forms";

interface SamePasswordValidatorError {
    matchPasswordError: boolean
}

interface SamePasswordValidatorOptions {
    /**
     * name of the password control
     */
    passwordFieldName: string;
}

/**
 * The validator function checks if the confirmPassword is similar to password
 * if the value is not same it will return { differentPasswordError: true }
 * if the value is same then it'll return null
 */
export function samePasswordValidator(options: SamePasswordValidatorOptions): ValidatorFn {
    return (control: AbstractControl): SamePasswordValidatorError | null => {

        const confirmPasswordField = control as FormGroup;

        /* istanbul ignore next */
        if (!confirmPasswordField)
            return null;

        const passwordField = control?.parent?.get(options.passwordFieldName) as FormGroup;

        /* istanbul ignore next */
        if (!passwordField)
            return null;

        const password = passwordField.value;

        const confirmPassword = confirmPasswordField.value;

        if (password !== confirmPassword) {
            return {
                matchPasswordError: true
            }
        }

        return null;
    };
}