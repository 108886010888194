import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ReplaySubject } from 'rxjs';

import { UserData } from 'src/app/modals/interfaces/user-data-model';
import { CwsRedirectService } from 'src/app/service/cws-redirect.service';
import { InactivityService } from 'src/app/service/inactivity.service';
import { LogoutService } from 'src/app/service/logout.service';
import { TabService } from 'src/app/service/tab.service';
import { common_urls, stop_mailing_certificates_url } from 'src/environments/environment';
import { LinkType } from '../constants/link-type.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  logoUrl: string = `${common_urls.cwsBaseUrl}/home?from=auth&cws=1`;
  manulifeWordUrl: string = `${common_urls.cwsBaseUrl}/home?cws=1`;
  isStpCwsPage = false;
  protected destroy$ = new ReplaySubject<boolean>(1);
  // to store current page url
  pageUrl!: string;
  // configure pages that should exclude from stp cws header title deviation
  stpTitleDeviationExcludedPages = [
    'customer-information',
    'password?token='
  ];
  isStopMailingPage = false;

  constructor(private oidcSecurityService: OidcSecurityService,
    private inactivityService: InactivityService,
    private tabService: TabService,
    private logoutService: LogoutService,
    private cwsRedirectService: CwsRedirectService,
    private router: Router) { }

  ngOnInit(): void {
    this.checkUrl();
  }

   /* Function to check url and configure header and footer */
   checkUrl(): void {

    this.pageUrl = this.router.url;
    // set isStpCwsPage to true if page url belongs to stp-cws-registration module
    if (this.pageUrl.includes('stp-cws-registration')) {
      this.isStpCwsPage = true;

      // set isStopMailingPage to true if page url belongs to stop-mailing-certificates / Mortgage
    } else if (this.pageUrl.includes('stop-mailing-certificates')) {
      this.isStopMailingPage = true;
      this.logoUrl = `${stop_mailing_certificates_url.cwsBaseUrl}/Home?from=auth&cws=1`;
      this.manulifeWordUrl = `${stop_mailing_certificates_url.cwsBaseUrl}/Home?from=auth&cws=1`;
    } else {
      
      this.isStpCwsPage = false
      this.isStopMailingPage = false;
    }
  }

  // show stp title when below conditions are satisfied:
  // 1. current page belongs to stp cws module
  // 2. current page is not configured to exclude stp header title deviation

  get showStpTitle(): boolean {
    return this.isStpCwsPage && !this.pageHasUrlSegment(this.stpTitleDeviationExcludedPages);
  }

  // returns true if page url has any passed url segments or else returns false
  private pageHasUrlSegment(urlSegments: string[]): boolean {
    return !!urlSegments.find((urlSegment) => this.pageUrl?.includes(urlSegment));
  }

  openSurveyModal() {
    const element = document.getElementById('modal-survey');
    element?.classList.add('open');
  }

  openLogoutModal() {
    const element = document.getElementById('modal-logout');
    element?.classList.add('open');
  }

  openModal() {
    this.inactivityService.resetTimer();
    this.oidcSecurityService.checkAuth().subscribe(({ userData }) => {
      const user_data: UserData = userData;
      console.log('user data in header', user_data);
      const customAttributes = user_data?.custom_attributes;
      if (customAttributes && customAttributes.enableSurvey === 'true') {
        this.openSurveyModal();
      } else {
        this.openLogoutModal();
      }
      document.body.style.overflow = 'hidden';
    });
  }

  /* function to redirect user on cws */
  // redirectToCWS() {
  //   // this.inactivityService.stopTrackingInactivity();
  //   // // window.location.href = this.cwsBackUrl;
  //   // this.oidcSecurityService.logoffLocal();
  //   // console.log('local logoff called');

  //   // const url: string = sessionStorage.getItem('cwsBackUrl') || '';
  //   // window.open(decodeURIComponent(url), '_self');
  //   this.cwsRedirectService.redirectToCWSBack();
  // }

  /* Function to redirect user to procUrl (CWS procedure url page) */

  redirectToProcPage(): void {

    this.cwsRedirectService.redirectToCwsProcUrl();

  }

  // logout for C360
  logout() {
    this.logoutService.OIDClogout(`${common_urls.cwsBaseUrl}/secur/logout.jsp`);
  }

  // Check stop-mailing-certificates route and navigate user to CWS
  // based on active tab
  navigateUser(linkType: LinkType | string): void {
    if (this.isStopMailingPage) {
      if (!this.tabService.isActiveTab()) {
        this.logoutService.navigateToCWSErrorPage();
        return;
      }
    }

    let navigateUrl = '';

    if (linkType === LinkType.logo) {
      navigateUrl = this.logoUrl;
    } else {
      navigateUrl = this.manulifeWordUrl;
    }

    window.open(navigateUrl, '_self');

  };
  
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  };
}
