<header class="l-header">
  <div class="l-header-top">
    <a (click)="navigateUser('LOGO')" class="p-header-logo u-hover"><span><img
          src="/assets/images/logo.svg" alt="マニュライフ生命"></span></a>
    <div class="p-header-util">
      <div class="p-header-guide">
        <a (click)="navigateUser('ManulifeWord')" class="p-header-guide__logo u-hover"><img src="/assets/images/logo02.png"
            alt="マニュライフ生命"></a>
        <p class="p-header-guide__label">
          <!-- show stp-title if showStpTitle returns true or else show title  -->
          {{ 'header.' + (showStpTitle ? 'stp-' : '') + 'title' | translate }}
        </p>
      </div>
      <!-- show logout button if page does not belong to stp cws module or stop mailing certificates module -->
      <div *ngIf="!isStpCwsPage && !isStopMailingPage" class="p-header-util__other">
        <!-- <div class="p-header-logout">
          <button class="p-header-logout__link" (click)="openModal()"><span>{{'header.logout-text' | translate}}</span></button>
        </div> -->
        <div class="p-header-logout">
          <button class="p-header-logout__link" (click)="redirectToProcPage()"><span>{{'header.back-text' | translate}}</span></button>
        </div>
      </div>
    </div>
    <!-- /l-header-top -->
  </div>
</header>

<app-survey-logout-modal></app-survey-logout-modal>
<app-logout-modal></app-logout-modal>
<app-info-modal></app-info-modal>