<div class="date-fields" *ngIf="dateFormGroup" [formGroup]="dateFormGroup">
    <!--year-->
    <div class="date-fields__item">
        <div class="date-fields__item__year-list">
            <ng-select class="dropdwon" id="yearDropdown" formControlName="year" [items]="yearList" [searchable]="false"
                bindValue="year" bindLabel="{{ displayJapaneseYear ? 'displayText': 'year'}}" placeholder="YYYY"
                [clearable]="false" (change)="yearSelected($event)"
                [ngClass]="{'has-err':dateFormGroup['invalid'] && dateFormGroup['touched'] , 'has-success':dateFormGroup['valid'] && dateFormGroup['touched']}">
            </ng-select>
        </div>
        <div class="date-fields__item__label">
            {{ "form-element.date-selector.year" | translate}}
        </div>
    </div>

    <!--month-->
    <div class="date-fields__item">
        <div class="date-fields__item__month-list">
            <ng-select class="dropdwon" id="monthDropdown" formControlName="month" [items]="monthList"
                [searchable]="false" bindValue="month" bindLabel="displayText" placeholder="MM" [clearable]="false"
                (change)="monthSelected($event)"
                [ngClass]="{'has-err':dateFormGroup['invalid'] && dateFormGroup['touched'] , 'has-success':dateFormGroup['valid'] && dateFormGroup['touched'] }">
            </ng-select>
        </div>
        <div class="date-fields__item__label">
            {{ "form-element.date-selector.month" | translate}}
        </div>
    </div>

    <!--day-->
    <div class="date-fields__item">
        <div class="date-fields__item__day-list">
            <ng-select class="dropdwon" id="dayDropdown" formControlName="day" [items]="dayList" [searchable]="false"
                bindValue="day" bindLabel="displayText" placeholder="DD" [clearable]="false"
                [ngClass]="{'has-err':dateFormGroup['invalid'] && dateFormGroup['touched'] , 'has-success':dateFormGroup['valid'] && dateFormGroup['touched']}">
            </ng-select>
        </div>
        <div class="date-fields__item__label">
            {{ "form-element.date-selector.day" | translate}}
        </div>
    </div>

    <!--date picker-->
    <div class="date-fields__item" *ngIf="!isDisabled && !hideDatePicker">
        <div class="date-fields__item__date-picker">
            <input class="show" [matDatepicker]="picker" (dateChange)="dateChanged($event)"
            [min]="minDate" [max]="maxDate">
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </div>
    </div>
</div>