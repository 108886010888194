import { AbstractControl, ValidatorFn } from '@angular/forms';
import { CompositionDetails, TextComposition, getCompositionWithText } from '../../utils/composition/composition';

interface JapaneseByteLengthError {
    requiredLength: number;
    actualLength: number;
}

const characterValueMap = new Map<Array<keyof TextComposition<string>>, number>(
    [
        // ascii characters are worth 1
        // half-width kana are worth 1
        [['ascii', 'katakanaHalf'], 1],

        // all else is worth 2
        [['hiragana', 'kanji', 'katakanaFull', 'romanFull', 'unknown'], 2],
    ],
);

const detailProps: Array<keyof CompositionDetails<string>> = ['numbers', 'symbols', 'text', 'whitespace'];

export function getLength(input: string): number {
    if (typeof input !== 'string' || input.length < 1) {
        return 0;
    }

    const comp = getCompositionWithText(input);

    return Array.from(characterValueMap.entries()).reduce((acc, cur) => {
        const [list, inc] = cur;

        list.forEach(prop => {
            const area = comp[prop] as CompositionDetails<string>;

            if (!area) {
                return;
            }

            detailProps.forEach(dp => {
                const text = area[dp];
                if (typeof text === 'string' && text.length > 0) {
                    acc += (text.length * inc);
                }
            });
        });

        return acc;
    }, 0);

}

export function japaneseMinByteLength(minLength: number | null): ValidatorFn {

    if (typeof minLength !== 'number' || minLength < 0) {
        throw new Error(`The length for japaneseMinByteLength was invalid`);
    }

    return (control: AbstractControl): { minJapaneseByteLength: JapaneseByteLengthError | null } | null => {
        const { value } = control;

        if (!value || typeof value !== 'string') {
            return null;
        }

        const actualLength = getLength(value);

        if (actualLength < minLength) {
            return {
                minJapaneseByteLength: {
                    actualLength,
                    requiredLength: minLength,
                },
            };
        }

        return null;
    };
}

export function japaneseMaxByteLength(maxLength: number | null): ValidatorFn {

    if (typeof maxLength !== 'number' || maxLength < 0) {
        throw new Error(`The length for japaneseMaxByteLength was invalid`);
    }

    return (control: AbstractControl): { maxJapaneseByteLength: JapaneseByteLengthError | null } | null=> {
        const { value } = control;

        if (!value || typeof value !== 'string') {
            return null;
        }

        const actualLength = getLength(value);

        if (actualLength > maxLength) {
            return {
                maxJapaneseByteLength: {
                    actualLength,
                    requiredLength: maxLength,
                },
            };
        }

        return null;
    };
}
