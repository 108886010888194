import { FooterConfig, LayoutDataModel } from "@cws-ui/ngx-cws-app-layout"
import { SVG_STRINGS } from "src/app/svg-strings"
import { common_urls } from "src/environments/environment"


export const FOOTER_CONFIG: FooterConfig = {
    links: [{
        title: "footer.links.link1",
        url: `${common_urls.footerNotesUrl}/MLJ_CWS_LoginStaticResource/Attachment/Notesonuse.pdf`,
    },
    {
        title: 'footer.links.link2',
        url: `${common_urls.footerNotesUrl}/MLJ_CWS_LoginStaticResource/Attachment/ManulifeMypageTermsofUse.pdf`,
    },
    {
        title: 'footer.links.link3',
        url: `http://www.manulife.co.jp/privacypolicy`,
    },
    {
        title: 'footer.links.link4',
        url: `http://www.manulife.co.jp/shop`,
    },
    {
        title: 'footer.links.link5',
        url: `https://support.manulife.co.jp/category?site_domain=mypage`,
    }],
    showSocialLinks: true,
    facebookLink: "https://www.facebook.com/Life2.0.ManulifeJapan",
    youtubeLink: "https://www.youtube.com/user/ManulifeJapan",
    showCopyRight: true,
    copyRightText: "©2016 Manulife Life Insurance Company.",

}
export const FraLayoutData: LayoutDataModel = {
    common_urls: common_urls,
    showSideNav: false,
    headLine: 'header.text',
    leftMenuItems: { leftMenuItems: [] },
    leftMenuSvgMap: SVG_STRINGS,
    footerConfig: FOOTER_CONFIG,
    logoUrl: `${common_urls.cwsBaseUrl}/home?from=auth&cws=1`,
    manulifeWordUrl: `${common_urls.cwsBaseUrl}/home?from=auth&cws=1`
}

export const fraScreenType = "fra";


