import { AbstractControl, ValidatorFn } from "@angular/forms";

interface ShouldNotContainValidatorError {
    disallowedTextError: boolean
}

interface ShouldNotContainValidatorOptions {
    /**
     * array of disallowed text
     */
    disallowedTexts: Array<string>;
}

/**
 * The validator function checks if the password is contains any of the disallowedText
 * if the value is similar to disallowed text it'll return { disallowedTextError: true }
 * if the value is not similar then it'll return null
 */
export function shouldNotContainValidator(options: ShouldNotContainValidatorOptions): ValidatorFn {
    return (control: AbstractControl): ShouldNotContainValidatorError | null => {

        const password = control.value;

        if(!password){
            return null;
        }

        if(options.disallowedTexts.includes(password)){
            return {
                disallowedTextError: true
            }
        }

        return null;
    };
}