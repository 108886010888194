import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatIconModule} from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';

@NgModule({
    imports: [
      MatExpansionModule,
      MatGridListModule,
      MatMenuModule,
      MatButtonModule,
      MatDividerModule,
      MatListModule,
      MatCardModule,
      MatTableModule,
      MatCheckboxModule,
      MatDialogModule,
      MatDatepickerModule,
      MatInputModule,
      MatFormFieldModule,
      MatNativeDateModule,
      MatCheckboxModule,
      MatRadioModule,
      MatIconModule
    ],
    exports: [
      MatExpansionModule,
      MatGridListModule,
      MatMenuModule,
      MatButtonModule,
      MatDividerModule,
      MatListModule,
      MatCardModule,
      MatTableModule,
      MatCheckboxModule,
      MatDialogModule,
      MatDatepickerModule,
      MatInputModule,
      MatFormFieldModule,
      MatNativeDateModule,
      MatCheckboxModule,
      MatRadioModule,
      MatIconModule
    ]
  })
  export class MaterialModule { }