import * as Ranges from './unicode-ranges';

export interface CompositionDetails<T> {
  /**
   * Symbol characters, such as @ - / []
   */
  symbols?: T;

  /**
   * Mostly phonetic characters
   */
  text?: T;

  /**
   * Arabic numerals. Half- and full-width varieties
   */
  numbers?: T;

  /**
   * Spaces
   */
  whitespace?: T;
}

export interface TextComposition<T, U = never> extends IObjectKeys{
  /**
   * Katakana full-width
   */
  katakanaFull?: Pick<CompositionDetails<T>, 'text' | 'symbols'> | U;

  /**
   * Katakana half-width. Single-byte kana.
   */
  katakanaHalf?: Pick<CompositionDetails<T>, 'text' | 'symbols'> | U;

  /**
   * Hiragana
   */
  hiragana?: Pick<CompositionDetails<T>, 'text' | 'symbols'> | U;

  /**
   * Kanji
   */
  kanji?: Pick<CompositionDetails<T>, 'text' | 'symbols' | 'whitespace'> | U;

  /**
   * ASCII. Half-width Roman / Latin
   */
  ascii?: CompositionDetails<T> | U;

  /**
   * Full-width Roman / Latin
   */
  romanFull?: Pick<CompositionDetails<T>, 'text' | 'numbers' | 'symbols'> | U;

  /**
   * Text was not matched by any specific rule
   */
  unknown?: T;
}

interface IObjectKeys {
  [key: string]: any;
}

type MapKey = [keyof TextComposition<any>, keyof CompositionDetails<any>];

const map = new Map<MapKey, number[][]>();

function buildMap() {
  if (map.size > 0) {
    return;
  }

  map.set(['katakanaFull', 'text'], [Ranges.KATAKANA_TEXT]);

  map.set(
    ['katakanaFull', 'symbols'],
    [Ranges.KATAKANA_SYMBOL_A, Ranges.KATAKANA_SYMBOL_B]
  );

  map.set(['katakanaHalf', 'symbols'], [Ranges.HALF_KANA_SYMBOLS_A]);

  map.set(['katakanaHalf', 'text'], [Ranges.HALF_KANA_TEXT]);

  map.set(['hiragana', 'text'], [Ranges.HIRAGANA_TEXT]);

  map.set(['hiragana', 'symbols'], [Ranges.HIRAGANA_SYMBOL]);

  map.set(
    ['romanFull', 'symbols'],
    [
      Ranges.FULL_ROMAN_SYMBOLS_A,
      Ranges.FULL_ROMAN_SYMBOLS_B,
      Ranges.FULL_ROMAN_SYMBOLS_C,
      Ranges.FULL_ROMAN_SYMBOLS_D,
      Ranges.FULL_ROMAN_SYMBOLS_E,
      Ranges.FULL_ROMAN_SYMBOLS_F,
    ]
  );

  map.set(
    ['romanFull', 'text'],
    [Ranges.FULL_ROMAN_TEXT_LOWER, Ranges.FULL_ROMAN_TEXT_UPPER]
  );

  map.set(['romanFull', 'numbers'], [Ranges.FULL_ROMAN_NUMBERS]);

  map.set(['kanji', 'symbols'], [Ranges.KANJI_SYMBOLS]);

  map.set(['kanji', 'whitespace'], [Ranges.KANJI_WHITESPACE]);

  map.set(['kanji', 'text'], [Ranges.KANJI_COMMON, Ranges.KANJI_RARE]);

  map.set(['ascii', 'whitespace'], [Ranges.ASCII_WHITESPACE]);

  map.set(
    ['ascii', 'text'],
    [Ranges.ASCII_TEXT_LOWER, Ranges.ASCII_TEXT_UPPER]
  );

  map.set(['ascii', 'numbers'], [Ranges.ASCII_NUMBERS]);

  map.set(
    ['ascii', 'symbols'],
    [
      Ranges.ASCII_SYMBOLS_A,
      Ranges.ASCII_SYMBOLS_B,
      Ranges.ASCII_SYMBOLS_C,
      Ranges.ASCII_SYMBOLS_D,
      Ranges.ASCII_SYMBOLS_E,
      Ranges.ASCII_SYMBOLS_F,
    ]
  );
}

function tester(char: string): MapKey {
  const code = char.charCodeAt(0);
  for (const [key, arr] of map) {
    const match = arr.find(([low, high]) => low <= code && high >= code);
    if (match) {
      return key;
    }
  }

  return ['unknown', null!];
}

/**
 * Returns the unicode ranges registered for the particular composition
 */
export function getUnicodeRanges(): TextComposition<number[]> {
  buildMap();

  return Array.from(map.entries()).reduce((acc, cur) => {
    const [[comp, detail], range] = cur;

    const existing = acc[comp] || {};
    return {
      ...acc,
      [comp]: {
        ...existing,
        [detail]: [...range],
      },
    };
  }, {} as TextComposition<number[]>);
}

/**
 * Returns the composition of the input text using simple boolean values
 * when the specific type is matched
 * @param text The text to get composition of
 */
export function getComposition(text: string): TextComposition<boolean> {
  const comp: TextComposition<boolean> = {};

  buildMap();

  for (const char of text) {
    const [c, d] = tester(char);
    if (c !== 'unknown') {
      comp[c] = {
        ...comp[c],
        [d]: true,
      };
    } else {
      comp.unknown = true;
    }
  }

  return comp;
}

/**
 * Returns the composition of the input text, including the matched text for each
 * area and type.
 * @param text The text to get composition of
 */
export function getCompositionWithText(text: string): TextComposition<string> {
  const comp: TextComposition<string> = {};

  buildMap();

  for (const char of text) {
    const [c, d] = tester(char);
    if (c === 'unknown') {
      if (!comp.unknown) {
        comp.unknown = char;
      } else {
        comp.unknown += char;
      }
    } else {
      const area: any = comp[c] || {};
      if (!area[d]) {
        area[d] = char;
      } else {
        area[d] += char;
      }
      comp[c] = area;
    }
  }

  return comp;
}
