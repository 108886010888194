export function create_UUID() {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
}

export function getCurrentTimestamp() {
  const date = new Date();

  const year = date.getFullYear();
  const month = padZero(date.getMonth() + 1);
  const day = padZero(date.getDate());
  const hours = padZero(date.getHours());
  const minutes = padZero(date.getMinutes());
  const seconds = padZero(date.getSeconds());

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function getIsoDateTimeString() {
  const date = new Date();
  const isoString = date.toISOString();
  return isoString;
}

export function padZero(value: any) {
  return String(value).padStart(2, '0');
}

/* function to return a current Timestamp in the pattern yyyymmddhhmmss */
export function timeStampYYYYMMDDHHMMSS() {
  const date = new Date();

  const year = date.getFullYear();
  const month = padZero(date.getMonth() + 1);
  const day = padZero(date.getDate());
  const hours = padZero(date.getHours());
  const minutes = padZero(date.getMinutes());
  const seconds = padZero(date.getSeconds());

  return `${year}${month}${day}${hours}${minutes}${seconds}`;
}

// Constants for FRA Ereport implementation
  export const deathBeneficiary = "死亡給付金受取人";
  export const proxyBeneficiary="指定代理請求人";
  export const annunityPayee="年金受取人";
  export const successorAnnuitypayee="後継年金受取人";
  export const noValue="指定なし";

