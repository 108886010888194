import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { DateSelectorComponent } from './date-selector/main-component/date-selector.component';
import { PasswordComponent } from './password/password.component';
import { DateFieldsComponent } from './date-selector/date-fields/date-fields.component';
import { SecurityNumberComponent } from './security-number/main-component/security-number.component';
import { SecurityNumberFieldsComponent } from './security-number/security-number-fields/security-number-fields.component';
import { TextInputComponent } from './text-input/text-input.component';
import { DigitOnlyDirective } from '../directives/digit-only/digit-only.directive';


@NgModule({
  declarations: [
    PasswordComponent,
    DateSelectorComponent,
    DateFieldsComponent,
    SecurityNumberComponent,
    SecurityNumberFieldsComponent,
    TextInputComponent,
    DigitOnlyDirective
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgSelectModule,
    MatDatepickerModule,
    ReactiveFormsModule,
  ],
  exports: [
    PasswordComponent,
    DateSelectorComponent,
    SecurityNumberComponent,
    TextInputComponent
  ]
})
export class FormElementsModule { }
