import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldBaseComponent } from '../field-base.component';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent extends FieldBaseComponent {

  isPasswordVisible = false;

  @Input()
  maxLength!: number;

  @Input()
  showGreenCheck!: boolean;

  get formControl() {
    /* istanbul ignore next */
      return this.control as FormControl
  }

  

}
