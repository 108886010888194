// https://en.wikipedia.org/wiki/Hiragana_(Unicode_block)
// https://www.unicode.org/charts/PDF/U3040.pdf
export const HIRAGANA_TEXT = [0x3040, 0x3098];
export const HIRAGANA_SYMBOL = [0x3099, 0x309f];

// https://en.wikipedia.org/wiki/Katakana_(Unicode_block)
// https://www.unicode.org/charts/PDF/U30A0.pdf
export const KATAKANA_TEXT = [0x30a1, 0x30fa];
export const KATAKANA_SYMBOL_A = [0x30a0, 0x30a0]; // just one char
export const KATAKANA_SYMBOL_B = [0x30fb, 0x30ff];

// https://en.wikipedia.org/wiki/Halfwidth_and_Fullwidth_Forms_(Unicode_block)
// https://www.unicode.org/charts/PDF/UFF00.pdf
export const FULL_ROMAN_SYMBOLS_A = [0xff00, 0xff0f];
export const FULL_ROMAN_SYMBOLS_B = [0xff1a, 0xff20];
export const FULL_ROMAN_SYMBOLS_C = [0xff3b, 0xff40];
export const FULL_ROMAN_SYMBOLS_D = [0xff5b, 0xff60]; // includes white parenthesis
export const FULL_ROMAN_SYMBOLS_E = [0xffe0, 0xffe1]; // currency
export const FULL_ROMAN_SYMBOLS_F = [0xffe5, 0xffe5]; // currency yen
export const FULL_ROMAN_NUMBERS = [0xff10, 0xff19];
export const FULL_ROMAN_TEXT_LOWER = [0xff41, 0xff5a];
export const FULL_ROMAN_TEXT_UPPER = [0xff21, 0xff3a];

// https://en.wikipedia.org/wiki/Halfwidth_and_Fullwidth_Forms_(Unicode_block)
// https://www.unicode.org/charts/PDF/UFF00.pdf
export const HALF_KANA_SYMBOLS_A = [0xff5f, 0xff65];
export const HALF_KANA_TEXT = [0xff66, 0xff9f];

// full-width punctuation and symbols
// https://www.unicode.org/charts/PDF/U3000.pdf
export const KANJI_WHITESPACE = [0x3000, 0x3000]; // just one character
export const KANJI_SYMBOLS = [0x3001, 0x303f]; // excludes whitespace character

export const KANJI_COMMON = [0x4e00, 0x9fff];
export const KANJI_RARE = [0x3400, 0x4dbf];

// https://upload.wikimedia.org/wikipedia/commons/d/dd/ASCII-Table.svg
// https://www.unicode.org/charts/PDF/U0000.pdf
// https://www.unicode.org/charts/PDF/U0080.pdf
export const ASCII_NUMBERS = [0x0030, 0x0039];
export const ASCII_SYMBOLS_A = [0x0021, 0x002f];
export const ASCII_SYMBOLS_B = [0x003a, 0x0040];
export const ASCII_SYMBOLS_C = [0x005b, 0x0060];
export const ASCII_SYMBOLS_D = [0x007b, 0x007e];
export const ASCII_SYMBOLS_E = [0x00a2, 0x00a3]; // currency
export const ASCII_SYMBOLS_F = [0x00a5, 0x00a5]; // currency yen

export const ASCII_TEXT_LOWER = [0x0061, 0x007a];
export const ASCII_TEXT_UPPER = [0x0041, 0x005a];
export const ASCII_WHITESPACE = [0x0020, 0x0020]; // just one character
