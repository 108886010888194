<div class="text-field">
    <!--label for text field-->
    <div class="text-field__label" *ngIf="this.displayLabel">
        {{ translationPrefix + fieldName | translate }}
        <span class="text-field__label--required" *ngIf="isRequired">*</span>
    </div>

    <!--input field for text-->
    <div class="text-field__input">
        <input [type]="inputType" placeholder="{{translationPrefix + placeHolder | translate}}" value=""
            class="text-field__input__element" [formControl]="formControl" [maxLength]="maxLength"
            [ngClass]="{'has-err':formControl.invalid && formControl.touched , 'has-success':formControl.valid && formControl.touched}">
    </div>
</div>