import { Month, Year } from "./date-selector.model"

//year list along with its japanese text
export const yearList: Array<Year> = [
    { year: 1900, displayText: "1900 (明治33)" },
    { year: 1901, displayText: "1901 (明治34)" },
    { year: 1902, displayText: "1902 (明治35)" },
    { year: 1903, displayText: "1903 (明治36)" },
    { year: 1904, displayText: "1904 (明治37)" },
    { year: 1905, displayText: "1905 (明治38)" },
    { year: 1906, displayText: "1906 (明治39)" },
    { year: 1907, displayText: "1907 (明治40)" },
    { year: 1908, displayText: "1908 (明治41)" },
    { year: 1909, displayText: "1909 (明治42)" },
    { year: 1910, displayText: "1910 (明治43)" },
    { year: 1911, displayText: "1911 (明治44)" },
    { year: 1912, displayText: "1912 (大正1 / 大正元)" },
    { year: 1913, displayText: "1913 (大正2)" },
    { year: 1914, displayText: "1914 (大正3)" },
    { year: 1915, displayText: "1915 (大正4)" },
    { year: 1916, displayText: "1916 (大正5)" },
    { year: 1917, displayText: "1917 (大正6)" },
    { year: 1918, displayText: "1918 (大正7)" },
    { year: 1919, displayText: "1919 (大正8)" },
    { year: 1920, displayText: "1920 (大正9)" },
    { year: 1921, displayText: "1921 (大正10)" },
    { year: 1922, displayText: "1922 (大正11)" },
    { year: 1923, displayText: "1923 (大正12)" },
    { year: 1924, displayText: "1924 (大正13)" },
    { year: 1925, displayText: "1925 (大正14)" },
    { year: 1926, displayText: "1926 (大正15 / 昭和元)" },
    { year: 1927, displayText: "1927 (昭和2)" },
    { year: 1928, displayText: "1928 (昭和3)" },
    { year: 1929, displayText: "1929 (昭和4)" },
    { year: 1930, displayText: "1930 (昭和5)" },
    { year: 1931, displayText: "1931 (昭和6)" },
    { year: 1932, displayText: "1932 (昭和7)" },
    { year: 1933, displayText: "1933 (昭和8)" },
    { year: 1934, displayText: "1934 (昭和9)" },
    { year: 1935, displayText: "1935 (昭和10)" },
    { year: 1936, displayText: "1936 (昭和11)" },
    { year: 1937, displayText: "1937 (昭和12)" },
    { year: 1938, displayText: "1938 (昭和13)" },
    { year: 1939, displayText: "1939 (昭和14)" },
    { year: 1940, displayText: "1940 (昭和15)" },
    { year: 1941, displayText: "1941 (昭和16)" },
    { year: 1942, displayText: "1942 (昭和17)" },
    { year: 1943, displayText: "1943 (昭和18)" },
    { year: 1944, displayText: "1944 (昭和19)" },
    { year: 1945, displayText: "1945 (昭和20)" },
    { year: 1946, displayText: "1946 (昭和21)" },
    { year: 1947, displayText: "1947 (昭和22)" },
    { year: 1948, displayText: "1948 (昭和23)" },
    { year: 1949, displayText: "1949 (昭和24)" },
    { year: 1950, displayText: "1950 (昭和25)" },
    { year: 1951, displayText: "1951 (昭和26)" },
    { year: 1952, displayText: "1952 (昭和27)" },
    { year: 1953, displayText: "1953 (昭和28)" },
    { year: 1954, displayText: "1954 (昭和29)" },
    { year: 1955, displayText: "1955 (昭和30)" },
    { year: 1956, displayText: "1956 (昭和31)" },
    { year: 1957, displayText: "1957 (昭和32)" },
    { year: 1958, displayText: "1958 (昭和33)" },
    { year: 1959, displayText: "1959 (昭和34)" },
    { year: 1960, displayText: "1960 (昭和35)" },
    { year: 1961, displayText: "1961 (昭和36)" },
    { year: 1962, displayText: "1962 (昭和37)" },
    { year: 1963, displayText: "1963 (昭和38)" },
    { year: 1964, displayText: "1964 (昭和39)" },
    { year: 1965, displayText: "1965 (昭和40)" },
    { year: 1966, displayText: "1966 (昭和41)" },
    { year: 1967, displayText: "1967 (昭和42)" },
    { year: 1968, displayText: "1968 (昭和43)" },
    { year: 1969, displayText: "1969 (昭和44)" },
    { year: 1970, displayText: "1970 (昭和45)" },
    { year: 1971, displayText: "1971 (昭和46)" },
    { year: 1972, displayText: "1972 (昭和47)" },
    { year: 1973, displayText: "1973 (昭和48)" },
    { year: 1974, displayText: "1974 (昭和49)" },
    { year: 1975, displayText: "1975 (昭和50)" },
    { year: 1976, displayText: "1976 (昭和51)" },
    { year: 1977, displayText: "1977 (昭和52)" },
    { year: 1978, displayText: "1978 (昭和53)" },
    { year: 1979, displayText: "1979 (昭和54)" },
    { year: 1980, displayText: "1980 (昭和55)" },
    { year: 1981, displayText: "1981 (昭和56)" },
    { year: 1982, displayText: "1982 (昭和57)" },
    { year: 1983, displayText: "1983 (昭和58)" },
    { year: 1984, displayText: "1984 (昭和59)" },
    { year: 1985, displayText: "1985 (昭和60)" },
    { year: 1986, displayText: "1986 (昭和61)" },
    { year: 1987, displayText: "1987 (昭和62)" },
    { year: 1988, displayText: "1988 (昭和63)" },
    { year: 1989, displayText: "1989 (昭和64 / 平成元)" },
    { year: 1990, displayText: "1990 (平成2)" },
    { year: 1991, displayText: "1991 (平成3)" },
    { year: 1992, displayText: "1992 (平成4)" },
    { year: 1993, displayText: "1993 (平成5)" },
    { year: 1994, displayText: "1994 (平成6)" },
    { year: 1995, displayText: "1995 (平成7)" },
    { year: 1996, displayText: "1996 (平成8)" },
    { year: 1997, displayText: "1997 (平成9)" },
    { year: 1998, displayText: "1998 (平成10)" },
    { year: 1999, displayText: "1999 (平成11)" },
    { year: 2000, displayText: "2000 (平成12)" },
    { year: 2001, displayText: "2001 (平成13)" },
    { year: 2002, displayText: "2002 (平成14)" },
    { year: 2003, displayText: "2003 (平成15)" },
    { year: 2004, displayText: "2004 (平成16)" },
    { year: 2005, displayText: "2005 (平成17)" },
    { year: 2006, displayText: "2006 (平成18)" },
    { year: 2007, displayText: "2007 (平成19)" },
    { year: 2008, displayText: "2008 (平成20)" },
    { year: 2009, displayText: "2009 (平成21)" },
    { year: 2010, displayText: "2010 (平成22)" },
    { year: 2011, displayText: "2011 (平成23)" },
    { year: 2012, displayText: "2012 (平成24)" },
    { year: 2013, displayText: "2013 (平成25)" },
    { year: 2014, displayText: "2014 (平成26)" },
    { year: 2015, displayText: "2015 (平成27)" },
    { year: 2016, displayText: "2016 (平成28)" },
    { year: 2017, displayText: "2017 (平成29)" },
    { year: 2018, displayText: "2018 (平成30)" },
    { year: 2019, displayText: "2019 (平成31 / 令和元)" },
    { year: 2020, displayText: "2020 (令和2)" },
    { year: 2021, displayText: "2021 (令和3)" },
    { year: 2022, displayText: "2022 (令和4)" },
    { year: 2023, displayText: "2023 (令和5)" },
    { year: 2024, displayText: "2024 (令和6)" },
    { year: 2025, displayText: "2025 (令和7)" },
    { year: 2026, displayText: "2026 (令和8)" },
    { year: 2027, displayText: "2027 (令和9)" },
    { year: 2028, displayText: "2028 (令和10)" }
]

//month list along with its displayText
export const monthList: Array<Month> = [
    {month: 1, displayText: '01'},
    {month: 2, displayText: '02'},
    {month: 3, displayText: '03'},
    {month: 4, displayText: '04'},
    {month: 5, displayText: '05'},
    {month: 6, displayText: '06'},
    {month: 7, displayText: '07'},
    {month: 8, displayText: '08'},
    {month: 9, displayText: '09'},
    {month: 10, displayText: '10'},
    {month: 11, displayText: '11'},
    {month: 12, displayText: '12'}
]