<mat-dialog-content class="message-modal__content">
  <!-- custom body for message modal -->
  <ng-container *ngTemplateOutlet="data.customBody"></ng-container>
</mat-dialog-content>
<mat-dialog-actions class="message-modal__action-buttons" align="center">
  <!-- close button for message modal -->
  <button mat-dialog-close>
    {{ data.actions.close.label | translate }}
  </button>
</mat-dialog-actions>
