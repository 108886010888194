<header class="l-header">
    <div class="l-header-top">
      <a class="p-header-logo u-hover"><span><img
            src="/assets/images/logo.svg" alt="マニュライフ生命"></span></a>
      <div class="p-header-util">
        <div class="p-header-guide">
          <a  class="p-header-guide__logo u-hover"><img src="/assets/images/logo02.png"
              alt="マニュライフ生命"></a>
          <p class="p-header-guide__label">{{'cws-registration.landing-page-text.title' | translate}}</p>
        </div>
      </div>
      <!-- /l-header-top -->
    </div>
  </header>

