import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginAllRoutesGuard } from 'angular-auth-oidc-client';

const routes: Routes = [
  {
    path: 'etax',
    loadChildren: () => import('./etax/etax.module').then(m => m.EtaxModule),
    canActivate: [AutoLoginAllRoutesGuard]
  },
  {
    path: 'cws-registration',
    loadChildren: () => import('./simplified-cws-registration/simplified-cws-registration.module').then(m => m.SimplifiedCwsRegistrationModule)
  },
  {
    path: 'ereport',
    loadChildren: () => import('./ereport/fra/fra.module').then(m => m.FraModule),
    canActivate: [AutoLoginAllRoutesGuard]
  },
  {
    path: 'family-registration',
    loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule),
    canActivate: [AutoLoginAllRoutesGuard]
  },
  {
    path: 'stop-mailing-certificates',
    loadChildren: () => import('./stop-mailing-certificates/stop-mailing-certificates.module').then(m => m.StopMailingCertificatesModule),
    canActivate: [AutoLoginAllRoutesGuard]
  },
  {
    path: 'gasa-fund',
    loadChildren: () => import('./gasa-fund-enhancement/gasa-fund-enhancement.module').then(m => m.GasaFundEnhancementModule),
    canActivate: [AutoLoginAllRoutesGuard]
  },
  {
    path: 'policy-download',
    loadChildren: () => import('./policy-download/policy-download.module').then(m => m.PolicyDownloadModule),
    canActivate: [AutoLoginAllRoutesGuard]
  },
  {
    path: 'stp-cws-registration',
    loadChildren: () => import('./stp-cws-registration/stp-cws-registration.module').then(m => m.StpCwsRegistrationModule)
  },
  {
    path: '', redirectTo: 'etax', pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
