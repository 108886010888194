import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldBaseComponent } from '../../field-base.component';

@Component({
  selector: 'app-security-number',
  templateUrl: './security-number.component.html',
  styleUrls: ['./security-number.component.scss']
})
export class SecurityNumberComponent extends FieldBaseComponent implements OnInit {

  @Input()
  firstBoxMaxLength!:number;

  @Input()
  firstBoxPlaceHolder!:string;
  
  @Input()
  secondBoxMaxLength!:number;

  @Input()
  secondBoxPlaceHolder!:string;

  public get formControl() {
    return this.control as FormControl;
  }

  constructor() {
    super();
  }

  public ngOnInit() {
  }
}
