<!-- required for gasa fund switching -->
<div *ngIf="!isCWSPage">
  <app-loader></app-loader>
  <app-layout [layoutData]="layoutData" (menuItemClicked)="handleMenuClick($event)">
    <main class="main-content" main>
      <router-outlet></router-outlet>
    </main>
    <app-logout-button header></app-logout-button>
  </app-layout>
</div>
<!-- <button mat-raised-button color="primary">Primary</button>
    <button mat-raised-button color="accent">Accent</button> -->


<!-- <button mat-raised-button color="primary" style="margin-top: 80px;" routerLink="/policies">Policies</button> -->
<!-- <button mat-button [matMenuTriggerFor]="menu">Menu</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item>Consent</button>
  <button mat-menu-item routerLink="/report" [queryParams]="{policyNumber:'500-1234567'}">Reports</button>
</mat-menu> -->

<!-- <div *ngIf="!isCWSPage">
  <app-loader></app-loader>
  <app-header></app-header>
  <main class="main-content">
    <router-outlet></router-outlet>
  </main>

  <app-footer></app-footer>
</div>

<!- required for SUR -->
<div *ngIf="isCWSPage">
  <app-loader></app-loader>
  <app-header *ngIf="isMortgage"></app-header>
  <app-cws-header *ngIf="!isMortgage"></app-cws-header>
  <main class="main-content cws-main-content">
    <router-outlet></router-outlet>
  </main>
  <app-footer *ngIf="isMortgage"></app-footer>
  <app-cws-footer *ngIf="!isMortgage"></app-cws-footer>
</div>

<!--Start : Family name change popup html body -->
<ng-template #showFamilyNameMsg>
  <div class="family-name-msg-wrap">
    <div>{{'app.common-modal.contract-information-change.show-family-name-msg1' | translate}}</div>
    <div>{{'app.common-modal.contract-information-change.show-family-name-msg2' | translate}}</div>
    <div class="family-name-msg-wrap__msg3-wrap">
      <span class="material-icons family-name-msg-wrap__done-icon">done</span>
      {{'app.common-modal.contract-information-change.show-family-name-msg3-1' | translate}}
      <span class="red-text">{{'app.common-modal.contract-information-change.show-family-name-msg3-2' |
        translate}}</span>
      {{'app.common-modal.contract-information-change.show-family-name-msg3-2' | translate}}
    </div>
    <div>{{'app.common-modal.contract-information-change.show-family-name-msg4' | translate}}</div>
  </div>
</ng-template>
<!--End : Family name change popup html body -->

<!--Start : Address change popup html body -->
<ng-template #addressChange>
  <div>{{'app.common-modal.address-change.text-1' | translate}}</div>
  <div>{{'app.common-modal.address-change.text-2' | translate}}</div>
</ng-template>
<!--End : Address change popup html body -->

<!--Start : Cancel Contract Change popup html body -->
<ng-template #cancelContractChange>
  <div class="cancel-contract-wrapper">
    <div>{{'app.common-modal.cancellation.contract-change.text-1' | translate}}
      <span class="red-text">{{'app.common-modal.cancellation.contract-change.text-2' | translate}}</span>
    </div>
    <div class="cancel-contract-wrapper__list">
      <ul>
        <li>
          {{'app.common-modal.cancellation.contract-change.text-3' | translate}}
          <span class="u-font-bold">{{'app.common-modal.cancellation.contract-change.text-4' | translate}}</span>
          {{'app.common-modal.cancellation.contract-change.text-5' | translate}}
        </li>
        <li class="u-font-bold">
          {{'app.common-modal.cancellation.contract-change.text-6' | translate}}
        </li>
      </ul>
    </div>
  </div>
</ng-template>
<!--End : Cancel Contract Change popup html body -->

<!--Start : Credit Card Change popup html body -->
<ng-template #creditCardChange>
  <div class="credit-card-wrapper">
    <div>{{'app.common-modal.procedurePremiumInsurance.creditCardChange.text-1' | translate}}</div>
    <div>{{'app.common-modal.procedurePremiumInsurance.creditCardChange.text-2' | translate}}</div>
    <div class="credit-card-wrapper__my-20px">
      <div>{{'app.common-modal.procedurePremiumInsurance.creditCardChange.text-3' | translate}}</div>
      <div class="credit-card-wrapper__ms-5px">{{'app.common-modal.procedurePremiumInsurance.creditCardChange.text-4' | translate}}</div>
    </div>
    <div>{{'app.common-modal.procedurePremiumInsurance.creditCardChange.text-5' | translate}}</div>
  </div>
</ng-template>
<!--End : Credit Card Change popup html body -->

<!--Start : Show Error FAQ Modal popup html body -->
<ng-template #errorFaqModal>
  <div class="red-text">
    <div>{{ errorFaqModalMsgText | translate }}</div>
    <div>{{'app.common-modal.change-name.text-5' | translate}}</div>
    <div>{{'app.common-modal.change-name.text-6' | translate}}</div>
  </div>
</ng-template>
<!--End : Show Error FAQ Modal popup html body -->

<!--Start : Progress Modal popup html body -->
<ng-template #progressModal>
  <div>
    <div>{{'app.common-modal.cancellation.text-1' | translate}}</div>
    <div>{{'app.common-modal.cancellation.text-2' | translate}}</div>
    <div *ngIf="progressModalText">{{ progressModalText | translate }}</div>
  </div>
</ng-template>
<!--End : Progress Modal popup html body -->

<!--Start : Tax Certificate popup html body -->
<ng-template #taxCertificate>
  <div>{{'app.common-modal.taxCertificateIssue.text-1' | translate}}</div>
</ng-template>
<!--End : Tax Certificate popup html body -->

<!-- error popus - start -->

<ng-template #error1Template>
  {{'gasa.dialog.errorCWS001' | translate}}

</ng-template>

<ng-template #error2Template>
  {{'gasa.dialog.errorCWS002' | translate}}
</ng-template>

<ng-template #error3Template>
  {{'gasa.dialog.errorCWS003' | translate}}
</ng-template>

<!-- error popups - end  -->
