import { Injectable } from '@angular/core';
import { common_urls } from 'src/environments/environment';
import { LogoutService } from './logout.service';

@Injectable({
  providedIn: 'root'
})
export class InactivityService {
  private inactivityTimer!: ReturnType<typeof setTimeout>;
  public inactivityDuration = 20 * 60 * 1000; // 20 minutes in milliseconds
  // private inactivityDuration = 20 * 1000 // 20 seconds in milliseconds

  constructor(private logoutService: LogoutService) {}

  public startTrackingInactivity() {
    console.log('timer start')
    this.resetTimer();
  }

  public resetTimer() {
    console.log('timer reset');
    
    clearTimeout(this.inactivityTimer);
    this.startInactivityWatch(this.callbackFunctionAfterInactivity.bind(this), this.inactivityDuration);
  }

  public stopTrackingInactivity() {
    clearTimeout(this.inactivityTimer);
  }

  public startInactivityWatch(callback: () => void, duration: number): void {
    this.inactivityTimer = setTimeout(callback, duration);
  }

  public callbackFunctionAfterInactivity(): void {
    // User is inactive for 20 minutes
    this.logoutService.OIDClogout(`${common_urls.cwsBaseUrl}/secur/logout.jsp`);
  }
}
