import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldBaseComponent } from '../field-base.component';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent extends FieldBaseComponent {

  @Input()
  maxLength = 9999;

  @Input()
  inputType: 'text' | 'number' = 'text'

  get formControl() {
    /* istanbul ignore next */
    return this.control as FormControl
  }



}