import { Component } from '@angular/core';

@Component({
  selector: 'app-cws-header',
  templateUrl: './cws-header.component.html',
  styleUrls: ['./cws-header.component.scss']
})
export class CwsHeaderComponent {

}
