import { AbstractControl, ValidatorFn } from "@angular/forms";

interface SecurityNumberValidatorOptions {
    /**
     * length of first box
     */
    firstBoxLength: number;

    /**
     * length of second box
     */
    secondBoxLength: number;
}

interface SecurityNumberValidatorError {
    /**
     * length of first box
     */
    securityNumberError: boolean;
}

export function securityNumberValidator(options: SecurityNumberValidatorOptions): ValidatorFn {
    return (control: AbstractControl): SecurityNumberValidatorError | null => {

        const securityNumber = control?.value

        if (!securityNumber) {
            //returning null if securityNumber field is null
            return null;
        }

        const securityNumberParts = securityNumber.split('-');

        if (securityNumberParts.length != 2) {
            return {
                securityNumberError: true
            };
        }

        /**
         * if the length of any of the box is different than configured value, then error will be returned
         */
        if (securityNumberParts[0].length != options.firstBoxLength
            || securityNumberParts[1].length != options.secondBoxLength) {
            return {
                securityNumberError: true
            };
        }

        return null;
    };
}