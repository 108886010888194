import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TabService {
  private readonly tabId: string; // current tab ID

  // property to store event listener and will be used to remove the listener
  private readonly handleStorageChangeBound: (event: StorageEvent) => void;

  constructor() {
    this.tabId = this.generateTabId();
    this.handleStorageChangeBound = this.handleStorageChange.bind(this);
    window.addEventListener('storage', this.handleStorageChangeBound);
  }

  // Generate random string
  private generateTabId(): string {
    return Math.random().toString(36).substring(2, 15);
  }

  // Callback function when any changes made to localStorage object
  private handleStorageChange(event: StorageEvent) {
    if (event.key === 'activeTab') {
      this.updateActiveTabState();
    }
  }

  private updateActiveTabState() {
    const activeTab = localStorage.getItem('activeTab');
    if (activeTab !== this.tabId) {
      // This tab is not active and remove event listener
      window.removeEventListener('storage', this.handleStorageChangeBound);
    }
  }

  // Check current active tab
  public isActiveTab(): boolean {
    return localStorage.getItem('activeTab') === this.tabId;
  }

  // Get current active tab id
  public getTabId(): string {
    return this.tabId;
  }

}
