<div class="p-header-logout">
    <button class="p-header-logout__link" (click)="openModal()">
      <span>{{ "header.logout-text" | translate }}</span>
    </button>
  </div>
  
  <ng-template #modalBody>
    <div class="modal-body">
      {{'logout-modal.body' | translate}}
      </div>
  </ng-template>

  <ng-template #surveyModalBody>
    <div class="modal-body">
      <p>{{'survey-logout-modal.body.text1' | translate}}</p>
      <p>{{'survey-logout-modal.body.text2' | translate}}</p>
      <p>{{'survey-logout-modal.body.text3' | translate}}</p>
    </div>
  </ng-template>