export function cloneDeep<T>(data: T): T {
  if (!data || typeof data !== 'object') {
    return data;
  }

  let clone: T;

  if (Array.isArray(data)) {
    clone = data.slice() as T; // unlink array reference
  } else {
    clone = { ...data }; // unlink object reference
  }

  const keys = Object.keys(clone as Object | Object[]);

  for (const key of keys) {
    clone[key as keyof T] = cloneDeep(clone[key as keyof T]); // recursively unlink reference to nested objects
  }

  return clone; // return unlinked clone
}
