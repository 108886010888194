import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { KATAKANA_2BYTES_CHAR } from "../configs";


export function kanaNameValidator(error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): any => {
        const inputStr = control?.value;
        /**
           * if any character in the input string is not a full-width kana character, then error will be returned
           */
        for (let i = 0; i < inputStr.length; i++) {
            if (KATAKANA_2BYTES_CHAR.indexOf(inputStr[i]) < 0) {
                return error;
            }
        }
        return null;
    };
}