import { Directive, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ReplaySubject } from 'rxjs';

@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class FieldBaseComponent {

    /**
     * Field name. Key in the form group for this control
     */
    @Input() public fieldName!: string;

    /**
     * The translation key prefix used for this field's labels
     */
    @Input() public translationPrefix!: string;

    /**
     * If true, the field's internal label will be rendered
     */
    @Input() public displayLabel!: boolean;

    /**
     * Place holder for the element
     */
    @Input() public placeHolder!: string;

    /**
     * The control from the form group
     */
    @Input() public control!: AbstractControl;

    /**
     * set it to true if the input value is required, '*' will be shown accordingly 
     */
    @Input() public isRequired!: boolean;

    /**
     * Called and completed upon ngOnDestroy lifecycle hook
     */
    protected destroy$ = new ReplaySubject<boolean>(1);

}
